@font-face {
    font-family: 'HK Grotesk';
    src: url('assets/fonts/HKGrotesk-ExtraBoldItalic.woff2') format('woff2'),
        url('assets/fonts/HKGrotesk-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('assets/fonts/HKGrotesk-ExtraLight.woff2') format('woff2'),
        url('assets/fonts/HKGrotesk-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('assets/fonts/HKGrotesk-BlackItalic.woff2') format('woff2'),
        url('assets/fonts/HKGrotesk-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('assets/fonts/HKGrotesk-Bold.woff2') format('woff2'),
        url('assets/fonts/HKGrotesk-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('assets/fonts/HKGrotesk-BoldItalic.woff2') format('woff2'),
        url('assets/fonts/HKGrotesk-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('assets/fonts/HKGrotesk-ExtraLightItalic.woff2') format('woff2'),
        url('assets/fonts/HKGrotesk-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('assets/fonts/HKGrotesk-ExtraBold.woff2') format('woff2'),
        url('assets/fonts/HKGrotesk-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('assets/fonts/HKGrotesk-Black.woff2') format('woff2'),
        url('assets/fonts/HKGrotesk-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('assets/fonts/HKGrotesk-LightItalic.woff2') format('woff2'),
        url('assets/fonts/HKGrotesk-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('assets/fonts/HKGrotesk-ThinItalic.woff2') format('woff2'),
        url('assets/fonts/HKGrotesk-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('assets/fonts/HKGrotesk-Italic.woff2') format('woff2'),
        url('assets/fonts/HKGrotesk-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('assets/fonts/HKGrotesk-Medium.woff2') format('woff2'),
        url('assets/fonts/HKGrotesk-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('assets/fonts/HKGrotesk-Light.woff2') format('woff2'),
        url('assets/fonts/HKGrotesk-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('assets/fonts/HKGrotesk-SemiBoldItalic.woff2') format('woff2'),
        url('assets/fonts/HKGrotesk-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('assets/fonts/HKGrotesk-Regular.woff2') format('woff2'),
        url('assets/fonts/HKGrotesk-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('assets/fonts/HKGrotesk-MediumItalic.woff2') format('woff2'),
        url('assets/fonts/HKGrotesk-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('assets/fonts/HKGrotesk-SemiBold.woff2') format('woff2'),
        url('assets/fonts/HKGrotesk-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('assets/fonts/HKGrotesk-Thin.woff2') format('woff2'),
        url('assets/fonts/HKGrotesk-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
