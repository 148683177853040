/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Ionic additional CSS */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* C3 library CSS */
@import "~c3/c3.min.css";

/* Custom fonts */
@import "fonts.scss";

* {
    font-family: 'HK Grotesk', 'Roboto', sans-serif;
    letter-spacing: -0.025em;
    font-weight: 700;
}

:root {
    --autoy-lightblue-accent: #B7EBF8;
    --autoy-lightpink-accent: #F9C4DD;
}

.font-regular {
    font-weight: 400;
}

.font-bold {
    font-weight: 700;
}

.ion-icon-inline {
    margin-bottom: -0.13em;
}

.centered-container {
    max-width: 768px;
    margin: auto;
}

ion-list-header {
    font-size: 1em;
    text-transform: uppercase;
}

/* C3 Charts */

.c3-line {
    stroke-width: 2px;
}

.c3-legend-item, .c3-axis-x text, .c3-axis-y text {
    font-family: var(--ion-font-family);
    font-size: 12px;
}

.c3 path, .c3 line { 
    stroke: var(--ion-color-step-600);
}

.c3 text { 
    fill: var(--ion-color-tertiary);
}

.c3-tooltip-container .c3-tooltip th {
    background-color: var(--ion-color-tertiary);
}

// Alertas
.patient-edit-alert {
    .alert-message {
        font-weight: 400;
        color: var(--ion-text-color)
    }
}
